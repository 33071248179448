<template>
  <v-theme-provider light>
    <base-section
      id="contact"
      class="tertiary text-secondary text-center"
      space="56"
    >
      <base-section-heading title="Kontakt" />
      <v-container class="pr-16 pl-16">
        <h3

          class="text-h5 font-weight-bold mb-4 text-center"
        >
          Ich freue mich auf Ihre Anfrage
        </h3>
      </v-container>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-sheet
              color="transparent"
              max-width="600"
            >
              <form
                v-if="!sent && !sending"
                id="contactform"
                class="contact-form"
                @submit.prevent="sendEmail"
              >
                <v-text-field
                  v-model="form.user_name"
                  label="Name"
                  name="from_name"
                  solo
                  flat
                />
                <v-text-field
                  v-model="form.user_email"
                  color="info"
                  label="Email"
                  solo
                  flat
                  type="email"
                  name="reply_to"
                />
                <div class="textarea-container">
                  <v-textarea
                    v-model="form.message"
                    solo
                    flat
                    :tile="false"
                    name="message"
                    rows="4"
                    cols="80"
                  />
                </div>
                <div class="send-button">
                  <base-btn
                    :tile="false"
                    color="primary"
                    rounded
                    outlined
                    class="secondary--text font-weight-bold"
                    @click="sendEmail"
                  >
                    Senden
                  </base-btn>
                </div>
              </form>
              <div v-if="!sent && sending">
                Wird gesendet...
              </div>
              <div v-if="sent">
                Vielen Dank, Wir melden uns so bald wie möglich bei
                Ihnen.
              </div>
            </v-sheet>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-container class="pr-16 pl-16">
              <div class="py-2" />
              <h3

                class="text-h6 font-weight-bold mb-2 text-center"
              >
                <a href="mailto:buzbreakdance@gmx.ch">buzbreakdance@gmx.ch</a> <br><br>+41 79 689 07 31
              </h3>
            </v-container>
            <div class="py-10" />
            <div class="ml-6">
              <v-btn
                icon
                href="https://www.facebook.com/profile.php?id=100015654855506"
                target="_blank"
              >
                <base-icon class="mb-8 mr-12">
                  mdi-facebook
                </base-icon>
              </v-btn>

              <v-btn
                icon
                href="https://www.instagram.com/buzbreaking/"
                target="_blank"
              >
                <base-icon class="mb-8">
                  mdi-instagram
                </base-icon>
              </v-btn>
            </div>
            <div class="py-5" />
            <v-container>
              <v-avatar
                size="80"
              >
                <img
                  :src="require('@/assets/contactcat.gif')"
                >
              </v-avatar>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import emailjs from 'emailjs-com'
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
    data () {
      return { sent: false, sending: false, form: {} }
    },
    methods: {
      sendEmail: function () {
        this.sending = true
        const self = this
        emailjs.sendForm('service_oqxmp1o', 'template_ro407qm', '#contactform', 'user_AEBhmLuhlaY6yX6wnKVug')
          .then((result) => {
            self.sending = false
            self.sent = true
            console.log('SUCCESS!', result.status, result.text)
          }, (error) => {
            console.log('FAILED...', error)
          })
      },
    },
  }

</script>

<style>
.v-application .text-h5 {
  letter-spacing: 0 !important;
}

.v-application .text-h4 {
  letter-spacing: 0.1rem !important;
}

.v-application .text-h6 {
  font-size: 1.3rem;
  letter-spacing: 0 !important;
  text-transform: lowercase;
  line-height: 1.5rem;
}

a{
  color:#0c0c0c!important;
}
</style>
